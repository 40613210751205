<template>
    <auth-page-template heading="Welcome to Advent!">
        <div>
            <div>
                <validated-input label="Username (E-mail)"></validated-input>
            </div>
            <div>
                <validated-input type="password" label="Password"></validated-input>
                <div class="fl-x fl-j-e">
                    <router-link class="text-decoration-none" to="/login/">Forgot password?</router-link>
                </div>
            </div>
            <div>
                <btn design="" block class="btn-bg-white mt-4" size="lg" border-radius="4" text="Login"></btn>
                <p class="mt-2">Don’t have an account ? <router-link to="/">Register Now</router-link></p>
            </div>
        </div>

    </auth-page-template>
</template>

<script>
import AuthPageTemplate from '@components/auth/AuthPageTemplate';

export default {
    name: 'LoginPage',
    components: { AuthPageTemplate }
};
</script>

<style scoped>

</style>
